.AlertWidget.level0 {
  fill: gray;
}

.AlertWidget.level1 {
  fill: green;
}

.AlertWidget.level2 {
  fill: #ffe01c;
}

.AlertWidget.level3 {
  fill: orange;
}

.AlertWidget.level4 {
  fill: #e80a0a;
}

