.AboutView {
  margin: 50px 20px;
}

.AboutView button, .AboutView select {
  color: inherit;
  background: rgba(211, 211, 211, .5);
  border: 1px solid #d3d3d3;
}

.exportOptions {
  display: flex;
  flex-direction: column;
}

.AboutView em {
  margin: 5px 0;
}

.exportUrl {
  display: flex;
  margin: 15px 0;
  max-width: 640px;
}

.url {
  margin: 0;
  width: 100%;
  font-size: 1em;
  font-family: monospace;
  text-overflow: ellipsis;
}

.exportUrl button {
  width: 100px;
  height: 40px;
}

.zoomrange {
  vertical-align: middle;
}

.aboutHeader {
  display: flex;
  align-items: center;
}

.aboutHeader img {
  image-rendering: pixelated;
  height: 100%;
  margin-right: 10px;
}

.aboutHeader h1 {
  display: inline-block;
  margin: 0 0 5px 0;
  font-size: 20px;
}

#delayInput {
  width: 45px;
  margin-left: 10px;
}