.SwitchBlinds {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SwitchBlinds section {
  display: flex;
  flex-grow: 1;
}

.SwitchBlinds h2 {
  width: 100%;
  padding: 10px;
  margin: 0;
  font-size: 1em;
  font-weight: lighter;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1;
}

.blindsOpen, .blindsStop, .blindsClose {
  flex-grow: 1;
  width: 33%;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform .3s ease-out;
}

.SwitchBlinds button:active {
  transform: scale(0.9);
  outline: none;
}

.SwitchBlinds button:focus {
  outline: none;
}

.blindsStop {
  margin: 0 10px;
}

