button.switch {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  transition: all .2s ease-out;
  font-size: 1.25em;
  font-weight: lighter;
  text-overflow: ellipsis;
  overflow: hidden;
}

button.switch:active {
  transform: scale(0.9);
}

