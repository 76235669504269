.WattWidget .value1::after {
  content: ' W';
}

.WattWidget .value2::after {
  content: ' kWh';
}

.WattWidget.withProd .value2::after {
  content: ' W (prod)';
}
