/*  Main css file */

body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
  color: inherit;
  filter: invert(30%);
}

.gridItem {
  overflow: hidden;
}

.gridItem.resizeable {
  animation-duration: .3s;
  animation-name: bgcolor;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes bgcolor {
  from {
    background-color: transparent;
  }
  to {
    background-color: #eee;
  }
}

.appbar {
  top: 0;
  right: 0;
  position: fixed;
  background-color: transparent;
  transform: translate3d(calc(100% - 52px), 0, 0);
  transition: transform .3s ease-in-out, background-color .3s linear;
  z-index: 2;
  will-change: transform;
}

.icon {
  width: 34px;
  height: 34px;
  vertical-align: middle;
}

.appbar button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 4px;
  outline: none;
}

.appbar.open {
  transform: translate3d(0, 0, 0);
}

.appbar button:first-child {
  transition: transform .3s ease-in-out, color .3s linear;
}

.appbar.open button:first-child {
  transform: rotate(-120deg);
}

.addDevices {
  margin: 50px 20px;
}

.addDevices i {
  color: #666;
  vertical-align: middle;
}

.addDevices .icon {
  width: 24px;
  height: 24px;
}

.layout {
  margin-bottom: 50px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.layout button {
  border-radius: 0;
}

.footer {
  position:fixed;
  left:0;
  bottom:0;
  width: 100%;
  z-index: 2;
  transition: transform .3s ease-out;
  will-change: transform;
  -webkit-user-select: none;
}

.footer.hidden {
  transform: translate3d(0, 50px, 0);
}

.footer .left {
  float: left;
}

.footer .title {
  text-align: center;
}

.footer .right {
  float: right;
}

.footer button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 4px;
  outline: none;
}

.zoomlabel {
    font-style: italic;
    font-size: small;
    padding: 2px;
    text-align: center;
}
