.ThermostatWidget {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ThermostatWidget .controls {
  display: flex;
  margin: 0;
  align-items: center;
  flex: 1;
}

.ThermostatWidget h2 {
  width: 100%;
  padding: 10px;
  margin: 0;
  font-size: 1em;
  font-weight: lighter;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1;
}

.ThermostatWidget .controls div,
.ThermostatWidget button {
  padding: 10px;
  flex: 1;
  box-sizing: border-box;
}

.ThermostatWidget .controls div {
  flex: 2;
  font-size: 1.75em;
  font-weight: lighter;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ThermostatWidget .controls div::after {
  content: '°';
}

.ThermostatWidget button {
  font-size: 1.75em;
  line-height: 1.75em;
}

@-webkit-keyframes blinker {
  15%, 40% {opacity: 0;}
  0%, 55%, 100% {opacity: 1;}
}

.ThermostatWidget .blink{
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: .5s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:ease-in-out;
}

