.Slider, .Slider .bar {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: all;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.Slider .bar {
  margin-left: -100%;
  box-sizing: border-box;
  border-right: 3px solid #4a4a4a;
  transition: transform .3s ease-out, opacity .3s linear;
  pointer-events: none;
}
