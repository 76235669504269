.SwitchDimmer {
  display: flex;
  height: 100%;
}

.SwitchDimmer .toggle {
  width: 15%;
  height: 100%;
  min-width: 40px;
  border: none;
  padding: 5px;
}

.SwitchDimmer .toggle:only-child {
  width: 100%;
  border: none;
}

.SwitchDimmer button {
  display: block;
  margin: 0;
  transition: all .3s ease-out;
  font-size: 1.12em;
  font-weight: lighter;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SwitchDimmer button:active {
  transform: scale(0.9);
  outline: none;
}

.SwitchDimmer button:focus {
  outline: none;
}

.SwitchDimmer .dimmerSlider {
  position: relative;
  width: 100%;
  margin-left: 10px;
}

.SwitchDimmer .dimmerContainer, .dimmerProgress, .dimmerProgressContent {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform .3s ease-out;
  pointer-events: none;
  user-select: none;
}

.SwitchDimmer .dimmerProgress {
  margin-left: -100%;
}

.SwitchDimmer .dimmerContainer, .dimmerProgressContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SwitchDimmer .dimmerContainer {
  font-size: 1.25em;
  font-weight: lighter;
  text-align: center;
}

.SwitchDimmer .dimmerProgressContent {
  position: relative;
  color: white;
}

.SwitchDimmer .dimmerContainer p {
  font-weight: lighter;
}
