.selectorContainer {
  height: 100%;
}

.selector {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.selector h2 {
  width: 100%;
  padding: 10px;
  margin: 0;
  font-size: 1em;
  font-weight: lighter;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1;
}

.selector > div {
  position: relative;
  flex: 1;
}

.selector > section {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.selector button {
  flex: 1;
  margin: 0 5px;
  height: auto;
}

@media (max-width: 414px) {
	.selector button {
		font-size: 1em;
	}
}

.selector button:first-child {
  margin-left: 0;
}

.selector button:last-child {
  margin-right: 0;
}

.selector .selectContainer {
  display: flex;
}

.selector select {
  width: 100%;
  padding: 0 30px 0 10px;
  font-size: 1.25em;
  font-weight: 100;
  text-align-last: center;
  border: none;
  border-radius: 0;
  outline: none;
  transition: color .2s ease-out, background .2s ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.selector select[disabled] {
  pointer-events: none;
}

.carret {
  font-style: normal;
  position: absolute;
  right: 3%;
  padding-right: 5px;
  top: calc(50% - 6px);
  pointer-events: none;
  transition: color .2s ease-out;
}

.selectorContainer h2 {
  position: absolute;
  text-align: center;
  width: 100%;
  pointer-events: none;
}
