
.PercentWidget h2::after {
  content: '%';
}

.PercentWidget .doughnut {
  transform: rotate(-90deg);
}

.PercentWidget .doughnut circle {
  stroke-dashoffset: 0;
  stroke-width: 40px;
  fill: transparent;
  transition: stroke-dashoffset .3s ease-out;
}

.PercentWidget .doughnut .bar {
  transform: translateZ(0);
}
