.GenericWidget {
  display: flex;
  height: 100%;
  align-items: center;
}

.GenericWidget h2,
.GenericWidget div {
  font-weight: lighter;
  overflow: hidden;
  text-overflow: ellipsis;
}

.GenericWidget h2 {
  margin: 5px 0;
  font-size: 1.25em;
}

.GenericWidget section {
  padding: 10px;
  flex: 2;
}

.GenericWidget svg {
  height: 100%;
  flex: 1;
}
