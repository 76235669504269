.DeviceListView {
  margin: 50px 20px 60px 20px;
}

.DeviceListView ul {
  padding-left: 10px;
}

.DeviceListView li {
  list-style-type: none;
  margin: 5px 0;
}

.DeviceListView li.unused {
  text-decoration: line-through;
}

.DeviceListView input {
  margin-right: 5px;
}

.DeviceListView label {
  cursor: pointer;
}

.searchbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.searchbar > div {
  white-space: nowrap;
  margin-right: 20px;
}
