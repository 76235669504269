.SwitchRGBW {
  display: flex;
  height: 100%;
}

.SwitchRGBW .toggle {
  width: 29%;
  height: 100%;
  min-width: 80px;
  border: none;
  padding: 5px;
}

.SwitchRGBW .toggle:only-child {
  width: 100%;
  border: none;
}

.SwitchRGBW button {
  display: block;
  margin: 0;
  transition: all .3s ease-out;
  font-size: 1.12em;
  font-weight: lighter;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SwitchRGBW button:active {
  transform: scale(0.9);
  outline: none;
}

.SwitchRGBW button:focus {
  outline: none;
}

.SwitchRGBW .colorSelect {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SwitchRGBW .hue {
  position: relative;
  height: 100%;
  margin: 10px 0 0 10px;
  flex: 1;
  background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 255, 0) 17%, rgb(0, 255, 0) 33%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 67%, rgb(255, 0, 255) 83%, rgb(255, 0, 0) 100%);
  cursor: pointer;
  opacity: .9;
  filter: saturate(85%);
}

.SwitchRGBW .swatch {
  position: relative;
  display: flex;
  height: 100%;
  margin: 0 0 0 10px;
  flex: 1;
}

.SwitchRGBW .swatch * {
  display: flex;
  flex: 1;
  padding: 0;
  border: none;
  background-color: transparent;
}

.SwitchRGBW button.white {
  width: 100%;
  background-color: white;
  border: 2px solid transparent;
}

.SwitchRGBW button.white:active {
  opacity: 0.9;
}

