.LoadingWidget {
  color: #808080;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.LoadingWidget  button {
  padding: 10px;
  margin-top: 10px;
  color: red;
  background-color: #ffe0e0;
  border: none;
  outline: none;
  transition: all .2s ease-out;
  font-size: 1em;
  font-weight: lighter;
  text-overflow: ellipsis;
  overflow: hidden;
}

.LoadingWidget button:active {
  transform: scale(0.9);
}

