.SettingsView {
  margin: 50px 20px;
}

.Status {
  color: red;
  font-weight: normal;
}

.Status.OK {
  color: green;
}

.SettingsView fieldset {
  max-width: 480px;
  padding: 5px 10px;
  margin-top: 5px;
  border: 1px solid #d3d3d3;
}

.SettingsView fieldset label {
  display: flex;
  align-items: center
}

.SettingsView input[type=text], input[type=password], input[type=url]  {
  display: block;
  width: 100%;
  max-width: 500px;
  font-size: .9em;
  margin: 5px 0;
}

.SettingsView input[type=submit], .SettingsView button {
  font-size: 1.12em;
  margin-left: 0;
  color: inherit;
  background: rgba(211, 211, 211, .5);
  border: 1px solid #d3d3d3;
}

.SettingsView em {
  font-size: .9em;
  color: #666;
}
